<template>
  <div class="changepwd">
    <img alt="Vue logo" src="../assets/login.png">

    <div class="center">
        <van-cell-group class="van-cell-group" :border="false">
          <van-field  input-align="right" type="tel" v-model="account" label="账号" placeholder="请输入您的注册手机号" />
        </van-cell-group>

        <van-cell-group class="van-cell-group" :border="false">
          <van-field  input-align="right" type="password" v-model="password" label="密码" placeholder="请输入您想设置的密码" />
        </van-cell-group>

        <van-cell-group class="van-cell-group" :border="false">
          <van-field  input-align="right" type="password" v-model="pwd" label="确认密码" placeholder="请确认输入您想设置的密码" />
        </van-cell-group>

        <nut-row>
          <nut-col span="16">
             <van-cell-group class="van-cell-group" :border="false">
              <van-field  input-align="right" v-model="phoneNumberCode" label="验证码" placeholder="请输入短信验证码" />
            </van-cell-group>
          </nut-col>
          <nut-col span="8">
            <div class="sendCode-btn" @click="sendCode">{{sendMsg}}</div>
          </nut-col>
        </nut-row>

      <div class="changepwd-btn" @click="register">修改密码</div>

      <van-divider class="login" v-on:click="$router.push('/login')">登录</van-divider>
    </div>

  </div>
</template>

<script>
import {R_parse_PhoneNumber,R_parse_PassWord} from '../utils/Regular'
export default {
  name: 'ChangePassword',
  data(){
    return{
      account:'',
      password:'',
      pwd:"",
      phoneNumberCode:"",
      time:60,
      timefn:null,
      sendMsg:"发送短信"
    }
  },

  methods:{
    sendCode(){
      if(!R_parse_PhoneNumber.test(this.account)){
        this.$toast.fail("账号需是手机号,方便忘记密码修改密码,请重新输入");
        return;
      }
      if(!R_parse_PassWord.test(this.password)){
        this.$toast.fail("密码必须为6-18位,请重新输入");
        return;
      }
      if(this.password!=this.pwd){
        this.$toast.fail("两次输入密码不一致,请重新输入");
        return;
      }
      if(this.timefn){
        return;
      }
      this.$dialog.confirm({
        title: this.account,
        message: '确认手机号，是否发送?',
      }) .then(() => {
          this.timefn = setInterval(()=>{
              --this.time;
              this.sendMsg = this.time + "秒";
              if(this.time == 0){
                this.time = 60;
                this.sendMsg = "发送短信";
                clearInterval(this.timefn);
                this.timefn = null;
              }
          }, 1000)
          this.axios.post('/app/phoneNumberCode', {
                    phoneNumber:this.account,
                    type:2
                  }).then((response) => {
                    console.log(response);
                    if(response==500){
                      return;
                    }
                    this.$toast.success("发送成功");
                  }).catch(function (error) {
                      console.log(error);
                  });
        }) .catch(() => {
          console.log('取消发送');
        });
    },
    register(){
      if(!R_parse_PhoneNumber.test(this.account)){
        this.$toast.fail("账号需是手机号,方便忘记密码修改密码,请重新输入");
        return;
      }
      if(!R_parse_PassWord.test(this.password)){
        this.$toast.fail("密码必须为6-18位字母、数字、特殊符号组成,请重新输入");
        return;
      }
      if(this.password!=this.pwd){
        this.$toast.fail("两次输入密码不一致,请重新输入");
        return;
      }
      if(this.phoneNumberCode.length!=6){
        this.$toast.fail("验证码输入不正确");
        return;
      }
      this.$dialog.confirm({
        title: this.account,
        message: '是否确认手机号，是否修改?',
      }) .then(() => {
        this.axios.post('/app/changePw', {
                  mobile:this.account,
                  password:this.password,
                  phoneNumberCode:this.phoneNumberCode
                }).then((response) => {
                  if(response==500){
                    return;
                  }
                  this.$toast.success("修改成功,请使用新密码登录");
                  this.$router.back();
                }).catch(function (error) {
                    console.log(error);
                });
      }) .catch(() => {
        console.log('取消发送');
      });

    }
}
}
</script>

<style scoped>
  .changepwd{
    padding: 15px 10px;
  }
  .changepwd img{
    width: 50%;
    padding: 24%;
  }
  .sendCode-btn{
    background: #00ef83;
    color: #FFFFFF;
    text-align: center;
    height: 46px;
    margin-top: -1px;
    line-height: 46px;
  }
  .changepwd-btn{
    width: 95%;
    height: 45px;
    line-height: 45px;
    margin: 15px auto 0;
    text-align: center;
    color: #FFFFFF;
    background: #00ef83;
    border-radius: 5px;
  }
  .login{
  }
  .van-cell-group{
    border-bottom: 1px solid #e7e7e7;
  }
  .center{
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 10px;
    bottom: 5px;
    background-color: white;
    padding: 10px;
  }
</style>
